<template>
  <div class="index-container">
    <img src="/img/bg/bg.png" />
    <div class="card">
      <img class="header-image" src="/img/pages/challenge.png" />
      <h1 v-t="'currencies.main'" class="title" />
      <h2 v-if="canRedirect" class="subtitle">
        {{ $t('loading') }}
      </h2>
      <div v-else>
        <p v-t="{ path: 'pages.index.open_question' }" class="subtitle" />
        <div class="buttons">
          <EssentialsDefaultButtonEvent class="w-48 m-2" @click="webHandler()">
            {{ $t('pages.index.open_browser') }}
          </EssentialsDefaultButtonEvent>
          <EssentialsDefaultButtonEvent class="w-48 m-2" @click="appHandler">
            {{ $t('pages.index.open_app') }}
          </EssentialsDefaultButtonEvent>
        </div>
        <a v-if="showSpecialLogo" :href="specialLink" class="special-logo">
          <img src="~/assets/img/specials/sutterluety.png" />
          <p v-t="{ path: 'pages.index.learn_more' }" class="link" />
        </a>
        <a v-else href="http://www.laendlepunkte.at" class="link">
          {{ $t('pages.index.learn_more') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  layout: 'static',
  data() {
    return {
      entry: '/me/overview',
      hidden: null,
      visibilityChange: null,
      OSs: {
        android: {
          store_prefix: 'https://play.google.com/store/apps/details?id=',
          test: /Android/i,
        },

        iOS: {
          store_prefix: 'https://itunes.apple.com/en/app/id',
          test: /iPhone|iPad|iPod/i,
        },
      },
      specialLink:
        'https://www.vol.at/laendlepunkte-gibts-jetzt-auch-bei-sutterluety/6494110',
    };
  },
  computed: {
    ...mapStores(useMainStore),
    canRedirect() {
      return Object.keys(this.$route.query).length > 0;
    },
    weblink() {
      return `${this.mainStore.frontend_url}?tee=${this.entry}${this.params}`;
    },
    applink() {
      return `volat://tee/webview?entry=${this.entry}${this.params}`;
    },
    appLinkAndroid() {
      return 'https://laendlepunkte.vol.at/';
    },
    params() {
      let paramstring = '';
      Object.entries(this.$route.query).forEach((q) => {
        paramstring += `&${q[0]}=${q[1]}`;
      });
      return paramstring;
    },
    iOS() {
      return (
        !!navigator.userAgent.match(/iPad/i) ||
        !!navigator.userAgent.match(/iPhone/i)
      );
    },
    webkit() {
      return !!navigator.userAgent.match(/WebKit/i);
    },
    iOSSafari() {
      return this.iOS && this.webkit && !navigator.userAgent.match(/CriOS/i);
    },
    showSpecialLogo() {
      return this.entry === '/me/voucher';
    },
  },
  mounted() {
    const subdomain = window.location.hostname.split('.')[0];
    const entry = this.mainStore.entries[subdomain];
    if (entry !== undefined) {
      this.entry = entry;
    }

    if (this.canRedirect) {
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        this.hidden = 'hidden';
        this.visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        this.hidden = 'msHidden';
        this.visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.hidden = 'webkitHidden';
        this.visibilityChange = 'webkitvisibilitychange';
      }

      this.redirect();
    }
  },
  methods: {
    userAgent() {
      for (const k in this.OSs) {
        // eslint-disable-line
        if (navigator.userAgent.match(this.OSs[k].test)) return k;
      }
      return '';
    },
    redirect() {
      if (this.userAgent()) {
        this.mobileHandler();
      } else {
        this.webHandler();
      }
    },
    mobileHandler() {
      const delay = 2000;

      // Store start time
      const start = new Date().getTime();
      let clicked = true;

      // Timeout to detect if the link worked
      let timeout = setTimeout(() => {
        // Check if any of the values are unset
        if (!clicked || !timeout) return;

        // Get current time
        const now = new Date().getTime();

        // Reset things
        clicked = false;
        timeout = null;

        // Has the user left the screen? ABORT!
        if (now - start >= delay * 2) return;

        // Open original link
        this.webHandler();
      }, delay);

      if (!this.iOSSafari) {
        // Go to app
        this.appHandler();
      }

      document.addEventListener(
        this.visibilityChange,
        () => {
          if (document[this.hidden]) {
            if (!clicked || !timeout) return;

            // Reset everything
            timeout = clearInterval(timeout);
            clicked = false;
          }
        },
        false
      );
    },
    webHandler() {
      window.location.href = this.weblink;
    },
    appHandler() {
      if (this.iOSSafari) {
        window.location.href = this.applink;
      } else {
        window.location.href = this.appLinkAndroid;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index-container {
  @apply min-h-screen flex flex-col items-center text-center mx-auto;
}

.card {
  @apply flex flex-col justify-center items-center text-center p-8;
  @apply bg-white -mt-8;
  border-radius: 2rem;
}

@media only screen and (min-width: 950px) {
  .index-container {
    @apply justify-center;
  }

  .card {
    @apply absolute -mt-24;
    top: 43%;
    left: 43%;
    max-width: 500px;
  }
}

.buttons {
  @apply flex flex-wrap justify-center;
}

.link {
  @apply text-base font-theme-text text-theme-primary underline mt-4 inline-block;
}

.header-image {
  @apply h-24 -mt-20;
}

.title {
  @apply text-5xl font-black font-theme-title text-theme-primary uppercase;
  @apply leading-10 mt-6 mb-4;
}

.subtitle {
  @apply text-xl font-theme-text text-theme-primary;
  @apply mb-2;
}

.special-logo {
  @apply mt-4 flex flex-col justify-center;

  img {
    @apply h-16 self-center;
  }
}
</style>
